import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import UserTopbar from "../components/userTopbar";

import away from '../images/away.svg'

import cups from '../images/cups.svg'

import edit from '../images/edit.svg'

import board from '../images/troop_board.svg'

import pc from '../images/pc.svg'

import Footer from "../components/footer.jsx";

import SelectableList from "../components/selectableList/index.jsx";

import argentina from '../images/argentina.png'

import user from '../images/user.svg'

import userFollow from '../images/user_follow.svg'

import filter from '../images/filter_icon.png'

import ClosedSelectableList from "../components/ClosedselectableList/index.jsx";

import SwitchButton from "../components/switchButton/index.jsx";


import '../scss/troop-view.scss'
import 'semantic-ui-css/semantic.min.css'

export default class isurus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: false,
      game: 'CSGO',
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }


  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="troop-view" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar onClick={() => this.setState({ player: true })} onClose={() => this.setState({ player: false })} player={this.state.player} user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">
                      <section className="games--section">
                        <div className="wrapper app">
                          <div className="linear--header">
                            <img className="icons" src={cups} />
                            <div className="actions">
                              <SwitchButton static text={'Abandonar troop'} icon={away} />
                              <SwitchButton text={'Siguiendo'} activeText={'Seguir'} icon={user} activeIcon={userFollow} />
                            </div>
                          </div>
                          <div className="profile--section">
                            <img src={board} />
                          </div>
                          <section className="video--section">
                            <h4 className="video--title">Jugadas destacadas de Isurus Gaming</h4>
                            <div onClick={() => this.setState({ player: true })} className="video--full">
                              <div className="detail">
                                <p className="bold">Final de torneo full-team con AK</p>
                                <p>5 kills al hilo y final ganada.</p>
                              </div>
                            </div>
                            <div onClick={() => this.setState({ player: true })} className="video--side p2">
                              <div className="detail">
                                <p className="bold">Triple kill a faka</p>
                              </div>
                            </div>
                            <div onClick={() => this.setState({ player: true })} className="video--side p3">
                              <div className="detail">
                                <p className="bold">Clutch en final de Valorant</p>
                              </div>
                            </div>
                            <div onClick={() => this.setState({ player: true })} className="video--side p4">
                              <div className="detail">
                                <p className="bold">Epic win en carrera de GTA V</p>
                              </div>
                            </div>
                            <div onClick={() => this.setState({ player: true })} className="video--side p5">
                              <div className="detail">
                                <p className="bold">Ace a AK en Dust</p>
                              </div>
                            </div>
                          </section>
                        </div>
                      </section>


                    </div>
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
